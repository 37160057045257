import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import clsx from 'clsx';
import {Route, Switch} from "react-router";
import Input from '@material-ui/core/Input';
import ManageView from "../PatientManageView";
import {followUpStop, finishTreat, stopTreatBill} from '../../redux/actions/patient_manage_action';
import Toast from "antd-mobile/lib/toast";
import SignPadPlus from '../../lib/signPadPlus';

import { DatePicker } from "antd";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import Moment from "moment";
import { Link } from 'react-router-dom';

import {Modal, Select, TimePicker, ConfigProvider } from 'antd';


//医生端-肺结核患者停止治疗情况表
const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

class FollowUpVisitPaper7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId:0,
            userId : localStorage.getItem("userId"),
            needFollowUpNum: 0,
            treatReportTimeStr: '',
            resistanceMedicineReportTimeStr: '',
            conclusionTimeTimeStr: '',
            modifyVisible: false,
            closeContactEstimates: [],
            userRegionType: 0,
        };

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);  // 13input框输入事件
        this.treatReportTimePickerOnChange = this.treatReportTimePickerOnChange.bind(this);
        this.resistanceMedicineReportTimePickerOnChange = this.resistanceMedicineReportTimePickerOnChange.bind(this);
        this.conclusionTimePickerOnChange = this.conclusionTimePickerOnChange.bind(this);
        this.showAddCloseContact = this.showAddCloseContact.bind(this);
        this.handleEventCancel = this.handleEventCancel.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.screeningTypeInputChange = this.screeningTypeInputChange.bind(this)
        this.acceptTreatInputChange = this.acceptTreatInputChange.bind(this)
    }

    //点击确认结案跳转到患者管理通知页
    handleConfirm(stopTreat) {
       if(!/^[0-9]+$/.test(this.state.needFollowUpNum)){
          Toast.show('请输入应随访次数的正确格式！')
          return false;
       }
       if(this.state.needFollowUpNum*1>=128){
          Toast.show('应随访次数超出上限，请重新输入！');
          return false;
       }

       var villageSignPad = this.refs.villageSign;
       var villageSignData = villageSignPad.toDataURL();
       var townSignPad = this.refs.townSign;
       var townSignData = townSignPad.toDataURL();

        const params = {
            id : stopTreat.stopTreat.id,
            patientId: this.state.patientId,
            needFollowUpNum : this.state.needFollowUpNum,
            treatReport: this.state.treatReport,
            treatReportTime: this.state.treatReportTimeStr.replace('年','-').replace('月','-').replace('日',''),
            treatReportHospital: this.state.treatReportHospital,
            treatReportReason: this.state.treatReportReason,
            resistanceMedicineReport: this.state.resistanceMedicineReport,
            resistanceMedicineReportTime: this.state.resistanceMedicineReportTimeStr.replace('年','-').replace('月','-').replace('日',''),
            resistanceMedicineReportHospital: this.state.resistanceMedicineReportHospital,
            resistanceMedicineReportReason: this.state.resistanceMedicineReportReason,
            conclusion: this.state.conclusion,
            conclusionTime: this.state.conclusionTimeTimeStr.replace('年','-').replace('月','-').replace('日',''),
            signVillage: villageSignData,
            signTown: townSignData,
            needCheck: this.state.needCheck,
            hasCheck: this.state.hasCheck,
            sputumSmear: this.state.sputumSmear,
            sputumCulture: this.state.sputumCulture,
            rabat: this.state.rabat,
            closeContactEstimates: this.state.closeContactEstimates,
        }
        this.props.finishTreat(params);
    }

    // 应访视患者次数 - 事件
    handleInputChange(event){
       //this.setState({
       //   needFollowUpNum: event.target.value
       //})
       const name = event.target.name;
       const value = event.target.value;
       this.setState({
          [name]: value
       });
    }

    getCloseContacts(){
        let records = []
        return records;
    }

    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId
        this.props.finishFollowUp(this.state.patientId);

        const patientInfo = {
            userId : this.state.userId,
            patientId : this.state.patientId
        }
        this.props.queryStopTreat(patientInfo);
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.finishTreatCode !== this.props.finishTreatCode) {
            if (nextProps.finishTreatCode === 200) {
                
                if(this.state.userRegionType == 6){
                    Toast.show("请提醒乡级管理医生最终结案处理！");
                }

                const path = `/doctorIndex/manage`;
                // const path = `${this.props.match.path}/Confirm`;
                this.props.history.push(path,{type: 'drug'});
            }
            
        }
        if(nextProps.finishTreatRet !== this.props.finishTreatRet){
            if (nextProps.finishTreatCode === 201){
                Toast.show("提交失败，请稍后再试！"+nextProps.finishTreatRet.msg);
            }
            if (nextProps.finishTreatCode === 403){
                Toast.show("村级未提交末次结案随访，请督导村级尽快完成！");
            }
        }

        if(nextProps.stopTreatInfo !== this.props.stopTreatInfo){
            if(nextProps.stopTreatInfo.userRegionType != null){
                this.setState({
                    userRegionType: nextProps.stopTreatInfo.userRegionType
                })
            }
        }
    }

    treatReportTimePickerOnChange(date, dateString) {
        this.setState({
            treatReportTimeStr:dateString,
        })
    }
    resistanceMedicineReportTimePickerOnChange(date, dateString) {
        this.setState({
            resistanceMedicineReportTimeStr:dateString,
        })
    }
    conclusionTimePickerOnChange(date, dateString) {
        this.setState({
            conclusionTimeTimeStr:dateString,
        })
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    showAddCloseContact(){
        this.setState({
            modifyVisible: true,
            contactName: '',
            screeningType: '',
            result: '',
            acceptTreatment: '',

        })
    }
    // 弹框取消事件
    handleEventCancel() {
        this.setState({
            modifyVisible: false,
        })
    }
    handleEditOk(){
        this.handleEventCancel()
        let contact = {
            name: this.state.contactName,
            screeningType: this.state.screeningType,
            result: this.state.result,
            acceptTreatment: this.state.acceptTreatment,
        }
        this.setState({
            closeContactEstimates: [...this.state.closeContactEstimates,contact]
        })
    }

    screeningTypeInputChange(event) {
        this.setState({ screeningType: event.target.value })
    }
    acceptTreatInputChange(event) {
        this.setState({ acceptTreatment: event.target.value })
    }


    render() {
        const {classes,stopTreat,stopTreatInfo} = this.props;
        if(stopTreat.needFollowUpNum && this.state.needFollowUpNum === 0){
            this.state.needFollowUpNum = stopTreat.needFollowUpNum;
        }

        // stopTreat.stopTreat.reason  ||  stopTreatInfo.reason
        return (
            <Switch>
                <Route path={`${this.props.match.path}/Confirm`} component={ManageView}/>

                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者停止治疗情况表</h5></Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="center" wrap="nowrap" spacing={2}>
                        <Typography className={classes.titleMain} h5 gutterBottom>
                            肺结核患者停止治疗情况表
                        </Typography>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography gutterBottom>
                                <span className={classes.titleBolds}>停止治疗时间：</span>
                                <span className={classes.spanDiv}>{stopTreatInfo.stopTreatTimeYear}</span>年 <span className={classes.spanDiv}>{stopTreatInfo.stopTreatTimeMonth}</span>月<span className={classes.spanDiv}>{stopTreatInfo.stopTreatTimeDay}</span>日
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                停止治疗原因：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <RadioGroup row aria-label="gender" name="customized-radios">
                                    <FormControlLabel checked={stopTreatInfo.reason === 1} className={classes.OptionDiv} value="1" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >治愈</span>/>
                                    <FormControlLabel checked={stopTreatInfo.reason === 2} className={classes.OptionDiv} value="2" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >完成治疗</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 3} className={classes.OptionDiv} value="3" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >结核死亡</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 4} className={classes.OptionDiv} value="4" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >非结核死亡</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 5} className={classes.OptionDiv} value="5" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >失败</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 6} className={classes.OptionDiv} value="6" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >丢失</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 7} className={classes.OptionDiv} value="7" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >诊断变更</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 8} className={classes.OptionDiv} value="8" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >转入耐多药治疗</span> />
                                    <FormControlLabel checked={stopTreatInfo.reason === 9} className={classes.OptionDiv} value="9" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >迁出本市/本区</span>/>
                                    <FormControlLabel checked={stopTreatInfo.reason === 90} className={classes.OptionDiv} value="90" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >不良反应结案</span>/>
                                    <FormControlLabel checked={stopTreatInfo.reason === 10} className={classes.OptionDiv} value="10" control={<StyledRadio/>} label=<span className={classes.OptionDiv} >其他</span>/>
                                </RadioGroup>
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={1}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                其他：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item justify="center" className={classes.spansDiv}>
                        <span className={classes.spansDiv}>{stopTreatInfo.otherReason}</span>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                全程管理情况
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={1}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                               应访视患者
                               <Input name="needFollowUpNum"
                                      value={this.state.needFollowUpNum}
                                      onChange={stopTreat.disableEdit != 1?this.handleInputChange:""}
                                      className={classes.shouldClass}
                                      inputProps={{'aria-label': 'description'}}
                               />
                               {/*<Input name="followupCount"
                                           value={stopTreat.followupCount}
                                           className={classes.shouldClass}
                                           onChange={this.handleInputChange}/>*/}
                               次，
                               实际访视<span className={classes.spanDiv}>{stopTreat.followUpNum}</span>次；
                            </Typography>
                        </Grid></Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                               患者在疗程中，应服药<span className={classes.spanDiv}>{stopTreat.needTakeDays}</span>次，
                               实际服药<span className={classes.spanDiv}>{stopTreat.takeDays}</span>次，
                               服药率<span className={classes.spanDiv}>{stopTreat.rate}</span>%
                            </Typography>
                        </Grid> </Grid>
                    
                    <div className={stopTreat.showEval == 1?'':classes.hideMe}>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} gutterBottom>
                                    结案评估情况
                                </Typography>
                            </Grid> 
                        </Grid>
                        {/*
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                应复查次数：<Input name="needCheck" value={this.state.needCheck} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                实际复查次数：<Input name="hasCheck" value={this.state.hasCheck} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                痰培养（次）：<Input name="sputumSmear" value={this.state.sputumSmear} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                胸片（次）：<Input name="rabat" value={this.state.rabat} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                痰涂片（次）：<Input name="sputumCulture" value={this.state.sputumCulture} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        */}
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>治疗记录卡：</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row name="treatReport" aria-label="treatReport" value={this.state.treatReport}
                                            onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio/>} label="未上报"/>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="已上报"/>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                记录卡上报日期：<span className={classes.title}>
                                                            <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                                                        allowClear={false}
                                                                        placeholder="请选择日期" className="date-picker-border"
                                                                        defaultValue={this.state.treatReportTime==null?'':Moment(this.state.treatReportTime, 'YYYY年MM月DD日')}
                                                                        onChange={this.treatReportTimePickerOnChange}/>
                                                        </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                记录卡上报单位：<Input name="treatReportHospital" value={this.state.treatReportHospital} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                记录卡未报原因：<Input name="treatReportReason" value={this.state.treatReportReason} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>


                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>耐多药服药卡：</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row name="resistanceMedicineReport" aria-label="resistanceMedicineReport" value={this.state.resistanceMedicineReport}
                                            onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio/>} label="未上报"/>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="已上报"/>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                服药卡上报日期：<span className={classes.title}>
                                                            <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                                                        allowClear={false}
                                                                        placeholder="请选择日期" className="date-picker-border"
                                                                        defaultValue={this.state.resistanceMedicineReportTime==null?'':Moment(this.state.treatReportTime, 'YYYY年MM月DD日')}
                                                                        onChange={this.resistanceMedicineReportTimePickerOnChange}/>
                                                        </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                服药卡上报单位：<Input name="resistanceMedicineReportHospital" value={this.state.resistanceMedicineReportHospital} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                服药卡未报原因：<Input name="resistanceMedicineReportReason" value={this.state.resistanceMedicineReportReason} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>




                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                定点医院治疗转归评估结果：<Input name="conclusion" value={this.state.conclusion} className={classes.inputs}
                                                onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                获得转归评估时间：<span className={classes.title}>
                                                            <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                                                        allowClear={false}
                                                                        placeholder="请选择日期" className="date-picker-border"
                                                                        defaultValue={this.state.conclusionTime==null?'':Moment(this.state.treatReportTime, 'YYYY年MM月DD日')}
                                                                        onChange={this.conclusionTimePickerOnChange}/>
                                                        </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} style={{"display":"none"}}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                涂阳患者密切接触者筛查：<Button variant="contained" color="primary" className={classes.buttonsContact} onClick={()=>{this.showAddCloseContact()}}>新增</Button>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                {this.state.closeContactEstimates.map((item,index)=>{
                                    return <Typography className={classes.title} color="textSecondary" gutterBottom>接触者姓名：<Link href="#" onClick={this.tryingEventClick} className={classes.blueTextValue}>{item["name"]}</Link></Typography>
                                })}
                            </Grid> 
                        </Grid>

                        <br/><br/>

                        <div className={styles.space}> </div>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={styles.title} color="textSecondary" gutterBottom>
                                &nbsp;&nbsp;村医签名：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='villageSign' name="villageSign" clearButton="true" height={300} className={styles.SignaturePadDiv} />
                        </Grid>
                        <br/><br/>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={styles.title} color="textSecondary" gutterBottom>
                                &nbsp;&nbsp;乡医签名：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='townSign' name="townSign" clearButton="true" height={300} className={styles.SignaturePadDiv} />
                        </Grid>
                        
                    </div>
                    
                    
                    <Grid container justify="center" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.buttons} onClick={()=>{this.handleConfirm(stopTreat)}}>
                                确认结案
                            </Button>
                        </Grid>
                    </Grid>



                    <Modal title="新增密接人"
                        visible={this.state.modifyVisible}
                        afterClose={this.handleEventCancel}
                        onCancel={this.handleEventCancel}
                        destroyOnClose={true}
                        forceRender={true}
                        footer={[
                            <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                            <Button key="submit" onClick={this.handleEditOk}>提交</Button>,
                        ]}
                    >
                        <Grid item xs={10} className={classes.bindContent}>

                            <Grid className={classes.bindContentFlex}>
                                <Grid className={classes.bindOptionLeftStyle}>姓名：</Grid>
                                <Input className={classes.bindOptionRightStyle}
                                name="contactName"
                                value={this.state.contactName}
                                onChange={this.handleInputChange} />
                            </Grid>

                            <Grid className={classes.bindContentFlex}>
                                <Grid className={classes.bindOptionLeftStyle}>筛查方式：</Grid>
                                <RadioGroup className={classes.bindOptionRightStyle} row aria-label="gender"
                                name="customized-radios"
                                onChange={this.screeningTypeInputChange}>
                                <FormControlLabel value="1" control={<StyledRadio />}
                                    label="询问" checked={this.state.screeningType == '1'} />
                                <FormControlLabel value="2" control={<StyledRadio />}
                                    label="痰检" checked={this.state.screeningType == '2'} />
                                <FormControlLabel value="3" control={<StyledRadio />}
                                    label="X光胸片" checked={this.state.screeningType == '3'} />
                                </RadioGroup>
                            </Grid>
                            <Grid className={classes.bindContentFlex}>
                                <Grid className={classes.bindOptionLeftStyle}>筛查结果：</Grid>
                                <Input className={classes.bindOptionRightStyle}
                                name="result"
                                value={this.state.result}
                                onChange={this.handleInputChange} />
                            </Grid>
                            <Grid className={classes.bindContentFlex}>
                                <Grid className={classes.bindOptionLeftStyle}>接受抗结核治疗：</Grid>
                                <RadioGroup className={classes.bindOptionRightStyle} row aria-label="gender"
                                name="customized-radios"
                                onChange={this.acceptTreatInputChange}>
                                <FormControlLabel value="1" control={<StyledRadio />}
                                    label="是" checked={this.state.acceptTreatment == '1'} />
                                <FormControlLabel value="0" control={<StyledRadio />}
                                    label="否" checked={this.state.acceptTreatment == '0'} />
                                </RadioGroup>
                            </Grid>


                        </Grid>
                    </Modal>


                </div>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
       stopTreat: state.patientManageReducer.stopTreat,
        stopTreatInfo : state.patientManageReducer.stopTreatInfo,
       finishTreatCode : state.patientManageReducer.finishTreatCode,
       finishTreatRet: state.patientManageReducer.finishTreatRet
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        finishFollowUp: (patientId) => {
            dispatch(followUpStop(patientId))
        },
        finishTreat:(params) => {
            dispatch(finishTreat(params))
        },
        queryStopTreat: (patientInfo) => {
            dispatch(stopTreatBill(patientInfo))
        }
    }
}

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        background: '#ffffff',
        height: '100vh',
        overflow: 'auto'
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    titleMain: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(0),
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
    },

    buttons: {
        margin: theme.spacing(2),
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    buttonsContact: {
        margin: theme.spacing(2),
        fontSize: '2.4vW',
        width: '25vw',
        height: '6vw',
        background: 'linear-gradient(to right,#3299CC, 50%, #9F5F9F)',
    },
    hr: {
        height: '0.3vw',
        // backgroundColor:'#e6e6e6',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90vw',
    },
    input: {
        margin: theme.spacing(1),
        width: '8vw'
    },
    inputs: {
        margin: theme.spacing(1),
        width: '50vw'
    },
    inputDiv: {
        margin: theme.spacing(1),
        width: '12vw'
    },
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500',
    },
    span: {
        float: 'right',
        marginRight: '3vw',
        // display:'inline-block'
    },
    titleBold: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: 0,
        marginTop: 0,
        lineHeight: '8vw',
        textAlign: 'justify',
        fontSize: '4.2vw',
        color: '#333333'
    },
    title: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '5vh',
        color: '#333333'
    },
    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },
    click: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    spanDiv: {
        textDecoration: 'underline',

    },
    shouldClass:{
       width: '10vw',
    },

    titleBolds: {
        marginLeft: theme.spacing(2),
        marginBottom: 0,
        marginTop: 0,
        lineHeight: '8vw',
        textAlign: 'justify',
        fontWeight: '700',
        fontSize: '4.2vw',
        color: '#333333'
    },

    OptionDiv: {
        fontSize: '4.2vw',
        textIndent: '0em',
    },

    spansDiv: {
        textDecoration: 'underline',
        margin: theme.spacing(0, 2),
        fontSize: '4.2vw',

    },
    blueTextValue: {
        color: '#096dd9',
        marginRight: theme.spacing(2),
        lineHeight: '10vw',
        height: '10vw',
     },
     bindOptionLeftStyle: {
        width: '35% !important',
        paddingLeft: '5%'
     },
     bindOptionRightStyle: {
        width: '60%'
     },
    hideMe: {
        display: 'none',
    },
    bindContentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '2% !important'
     },
     bindModalContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     },
     bindContent: {
        backgroundColor: '#FFFFFF'
     },
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpVisitPaper7));
