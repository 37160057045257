import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import { stopTreatBill, confirmStopTreat } from "../../redux/actions/patient_manage_action";

import Api from "../../redux/api/api";
import axios from "axios";
import Toast from "antd-mobile/lib/toast";

const useStyles = makeStyles({
    root: {

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

//肺结核患者停止治疗管理通知单
class FollowUpVisitPaper4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: 0,
            direction: '',
            value: 0,
            index: 0,
            userId: localStorage.getItem("userId"),
            doctorId: localStorage.getItem("doctorId")
        };
        this.handleConnect = this.handleConnect.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleLastFollowUp = this.handleLastFollowUp.bind(this);
        this.goFollowupHistpry = this.goFollowupHistpry.bind(this)
    }


    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId;
        const patientInfo = {
            userId: this.state.userId,
            patientId: this.state.patientId
        }
        this.props.queryStopTreat(patientInfo);
    }

    //点击跳转到患者停止治疗详情页
    handleLastFollowUp(item) {
        let id = item.id;
        this.props.confirmStopTreat(id);
        const path = `/doctorIndex/manage/followupvisitpaper2`;
        this.props.history.push(path, { "stopTreat": "true", "patientId": item.patientId, "followUpType": 2, "patientName": item.patientName, "followUpId": 2, "orderDate": item.stopTreatTime, "treatMonth": item.treatMonth });
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverInfo != this.props.serverInfo) {

        }
        if (nextProps.loggedIn != this.props.loggedIn) {
            if (nextProps.loggedIn == true) {
                //成功登录,则转到主页
                const path = `/`;
                this.props.history.push(path);
            }
        }
        if (nextProps.retCode === 403) {
            window.location.href = "/doctor"
        }
    }

    handleConnect() {

    }

    handleNavBack() {
        this.props.history.goBack();
    }

    onClickDone(stopInfo) {
        /*
        const url = Api.getRootUrl() + '/api/stopTreat/directFinish'
        const params = {
            id: stopInfo.id,
            patientId: this.state.patientId,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token")
        }
        let that = this;
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.info("提交成功!")
                that.props.history.goBack();
            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
            */

        const path = `/doctorIndex/manage/stopTreat`;
        this.props.history.push(path, {"patientId": this.state.patientId});

    }

    goFollowupHistpry(){
        const item = {
            "patientId": this.state.patientId,
            "patientName": this.props.PatientStopTreat.patientName,
            "patientNumber": this.props.PatientStopTreat.patientNumber
        }
        const path = `/doctorIndex/manage/followuphistory`; //随访历史
        this.props.history.push(path, item);
    }

    render() {
        const { classes, PatientStopTreat } = this.props;

        return (
            <div className={classes.root}>
                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} alt='' className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={10} className={classes.headers}>
                        <h4 className={classes.huanzhe}>肺结核患者停止治疗管理通知单</h4>
                    </Grid>
                    <Grid item justify="center" className={classes.hideMe1} xs={2}>
                        <Typography h6 className={classes.title} onClick={this.goFollowupHistpry} >随访</Typography>
                    </Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                <Grid className={classes.rootDiv}>
                    <Grid container justify="center" wrap="nowrap" spacing={0}>
                        <Typography className={classes.title1}>肺结核患者停止治疗<p className={classes.titlePTypogra}>管理通知单</p></Typography>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Typography className={classes.titleBold} gutterBottom>
                            <span className={classes.spanDiv}>{PatientStopTreat.patientVillage}</span>社区卫生服务中心：
                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0}>
                        <Typography className={classes.titleBold} gutterBottom>
                            你中心辖区<span className={classes.spanDiv}>{PatientStopTreat.patientTown}</span>
                            <span>街道/乡镇</span>
                            <span className={classes.spanDiv}>{PatientStopTreat.patientVillage}</span>
                            居委会/村的患者<span className={classes.spanDiv}>{PatientStopTreat.patientName}</span>
                            （<span className={classes.spanDiv}>{PatientStopTreat.patientSex}</span>，
                            <span className={classes.spanDiv}>{PatientStopTreat.patientAge}</span>岁，联系电话：
                            <span className={classes.spanDiv}>{PatientStopTreat.patientPhone}</span>），已于
                            <span className={classes.spanDiv}>{PatientStopTreat.stopTreatYear}</span>年 <span
                                className={classes.spanDiv}>{PatientStopTreat.stopTreatMonth}</span>月
                            <span className={classes.spanDiv}>{PatientStopTreat.stopTreatDay}</span>日停止治疗，停止治疗原因为：

                            <RadioGroup className={classes.OptionsDiv} row aria-label="gender" name="customized-radios">
                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />}
                                    label="治愈" checked={PatientStopTreat.stopTreatReasonId == '1'} />
                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />}
                                    label="完成治疗" checked={PatientStopTreat.stopTreatReasonId == '2'} />
                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />}
                                    label="结核死亡" checked={PatientStopTreat.stopTreatReasonId == '3'} />
                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio />}
                                    label="非结核死亡" checked={PatientStopTreat.stopTreatReasonId == '4'} />
                                <FormControlLabel className={classes.OptionDiv} value="5" control={<StyledRadio />}
                                    label="失败" checked={PatientStopTreat.stopTreatReasonId == '5'} />
                                <FormControlLabel className={classes.OptionDiv} value="6" control={<StyledRadio />}
                                    label="丢失" checked={PatientStopTreat.stopTreatReasonId == '6'} />
                                <FormControlLabel className={classes.OptionDiv} value="7" control={<StyledRadio />}
                                    label="诊断变更" checked={PatientStopTreat.stopTreatReasonId == '7'} />
                                <FormControlLabel className={classes.OptionDiv} value="8" control={<StyledRadio />}
                                    label="转入耐多药治疗" checked={PatientStopTreat.stopTreatReasonId == '8'} />
                                <FormControlLabel className={classes.OptionDiv} value="9" control={<StyledRadio />}
                                    label="迁出本市/本区" checked={PatientStopTreat.stopTreatReasonId == '9'} />
                                <FormControlLabel className={classes.OptionDiv} value="9" control={<StyledRadio />}
                                    label="不良反应结案" checked={PatientStopTreat.stopTreatReasonId == '90'} />
                                <FormControlLabel className={classes.OptionDiv} value="10" control={<StyledRadio />}
                                    label="其他" checked={PatientStopTreat.stopTreatReasonId == '10'} />
                            </RadioGroup>



                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={1}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                其他：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item justify="center" className={classes.spansDiv}>
                        <span className={classes.spansDiv}>{PatientStopTreat.otherReason}</span>
                    </Grid>
                    <hr className={classes.hr}></hr>


                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Typography className={classes.mainDiv} gutterBottom>
                            请做好最后一次随访后停止对该患者的治疗管理。谢谢您的配合！
                        </Typography>
                    </Grid>


                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.mainDiv} gutterBottom>
                                我所联系人： <span className={classes.spanDiv}>{PatientStopTreat.hospitalDoctor}</span>
                            </Typography>
                        </Grid> </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Typography className={classes.mainDiv} gutterBottom>
                            联系电话： <span className={classes.spanDiv}>{PatientStopTreat.hospitalPhone}</span>
                        </Typography>
                    </Grid>

                    <Grid container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <span className={classes.spanDiv}>{PatientStopTreat.hospitalName}</span>区结防所
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <span className={classes.spanDiv}>{PatientStopTreat.currentYear}</span>年
                                <span className={classes.spanDiv}>{PatientStopTreat.currentMonth}</span>月
                                <span className={classes.spanDiv}>{PatientStopTreat.currentDay}</span>日
                            </Typography>
                        </Grid>
                    </Grid>

                    <div className={PatientStopTreat?.blocked == null?"":classes.hideMe}>
                    <Grid container justify="center" className={PatientStopTreat?.directFinish == 1 ? classes.hideMe : classes.buttonBox}>
                        <Button variant="contained" className={classes.margin}
                            onClick={() => {
                                this.handleLastFollowUp(PatientStopTreat)
                            }} >
                            确认并对患者最后随访
                        </Button>
                    </Grid>
                    <Grid container justify="center" className={PatientStopTreat?.directFinish == 1 ? classes.buttonBox : classes.hideMe}>
                        <Button variant="contained" className={classes.margin}
                            onClick={() => {
                                this.onClickDone(PatientStopTreat)
                            }} >
                            结案处理
                        </Button>
                    </Grid>
                    </div>
                    <Grid container justify="center" className={PatientStopTreat?.blocked == 1 ? classes.buttonBox:classes.hideMe}>
                        <Button variant="contained" className={classes.margin} >
                            等待乡级结案确认
                        </Button>
                    </Grid>
                    <Grid container justify="center" className={PatientStopTreat?.blocked == 2 ? classes.buttonBox:classes.hideMe}>
                        <Button variant="contained" className={classes.margin} >
                            等待村级末次随访确认
                        </Button>
                    </Grid>

                </Grid>
            </div>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        retCode: state.patientManageReducer.retCode,
        serverInfo: state.serverInfo,
        PatientStopTreat: state.patientManageReducer.PatientStopTreat
    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
        queryStopTreat: (patientInfo) => {
            dispatch(stopTreatBill(patientInfo))
        },
        confirmStopTreat: (id) => {
            dispatch(confirmStopTreat(id))
        }
    };
};
//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
        overflow: 'hidden'

    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'

    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(1),
        width: '30vw',
    },
    buttons: {
        margin: theme.spacing(1),
        width: '60vw',
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80vw',
    },
    input: {
        margin: theme.spacing(1),
        width: '10vw'
    },
    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },

    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        textAlign: 'center',
        fontSize: '5vw',
        color: '#333333',
        fontWeight: '700',
        // lineHeight: '2vw'
    },
    titlePTypogra: {
        margin: '0',
        padding: '0'
    },
    span: {
        float: 'right',
        marginRight: '3vw'
    },
    titleBold: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        textAlign: 'justify',
        margin: 0,
        color: '#333333'
    },
    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },

    OptionDiv: {
        fontSize: '4.2vw',
        textIndent: '0em',
    },

    buttonBox: {
        height: '30vw',
        margin: 0,
        padding: '0'
    },
    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '100vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: theme.spacing(3),
        marginbottom: theme.spacing(3),
        color: '#ffffff',
        padding: 0

    },
    spacemargin: {
        marginTop: theme.spacing(2),
        marginbottom: theme.spacing(0),
    },

    spanDiv: {
        textDecoration: 'underline',
    },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    OptionsDiv: {
        marginLeft: theme.spacing(3),
    },

    titleBolds: {
        marginLeft: theme.spacing(2),
        marginBottom: 0,
        marginTop: 0,
        lineHeight: '8vw',
        textAlign: 'justify',
        fontWeight: '700',
        fontSize: '4.2vw',
        color: '#333333'
    },

    spansDiv: {
        textDecoration: 'underline',
        margin: theme.spacing(0, 2),
        fontSize: '4.2vw',

    },
    hideMe: {
        display: 'none',
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpVisitPaper4));
