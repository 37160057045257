import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
   followUpHistory,
   followUpVisitPaper,
   queryFollowUpBasicInfo,
   uploadFile
} from '../../redux/actions/patient_manage_action';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import Toast from "antd-mobile/lib/toast";

//弹出框
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import { amber, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
// 引入antd 时间组件
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';
import Moment from "moment";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";


import Api from "../../redux/api/api";
import axios from "axios";
import { RadioSharp } from '@material-ui/icons';



//日常随访单
class FollowUpHistory extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userId: localStorage.getItem("userId"),
         patientId: '',
         patientName: '',
         followups: [],
         treatMonth: '',
         orderDate: '',
      };

      this.handleNavBack = this.handleNavBack.bind(this);

      this.handleHistoryFollowUpDetails = this.handleHistoryFollowUpDetails.bind(this);
      this.queryFollowupHistory = this.queryFollowupHistory.bind(this);
      this.addFollowup = this.addFollowup.bind(this);
   }

   componentDidMount() {
      this.state.patientName = this.props.location.state.patientName
      this.state.patientId = this.props.location.state.patientId
      this.state.patientNumber = this.props.location.state.patientNumber
      this.state.treatMonth = this.props.location.state.treatMonth
      this.state.orderDate = this.props.location.state.orderDate

      //查询随访历史
      //this.props.queryFollowUpHistory(this.state.patientId)
      this.queryFollowupHistory()
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {

   }

   queryFollowupHistory() {
      const url = Api.getRootUrl() + '/api/followUp/followUpHistory'
      const params = {
         patientId: this.state.patientId,
         userId: localStorage.getItem("userId"),
         token: localStorage.getItem("token")
      }
      let that = this;
      axios.post(url, params).then(function (rsp) {
         console.log(rsp.data.code)
         if (rsp.data.code == 200) {
            that.setState({
               followups: rsp.data.data
            });
         } else {
            Toast.info("查询失败!" + rsp.data.msg)
         }
      }).catch(function (err) {
         console.log(err)
         Toast.info("查询失败，请稍后再试！")
      })
   }

   //点击返回
   handleNavBack() {
      window.history.go(-1);
   }

   //随访历史明细页
   handleHistoryFollowUpDetails(item) {
      var params = {
         complicationDesc: this.state.complicationDesc,
         wrongReactDesc: this.state.wrongReactDesc
      }
      if (item.source === 1) {
         const path = `/doctorIndex/manage/FirstFollowUpHistoryDetail`;
         this.props.history.push(path, { "detail": item });
      } else if (item.source === 2) {
         const path = `/doctorIndex/manage/FollowUpHistoryDetail`;
         this.props.history.push(path, { "detail": item });
      }
   }

   addFollowup(){
      let followUpType = 1;
      if(this.state.followups != undefined && this.state.followups.length > 0){
         followUpType = 2;
      }
      console.log(followUpType)
      if (followUpType == 1) {
         const path = `/doctorIndex/manage/followupvisitpaper`;  //首次随访(FollowUpVisitPaper3)
         this.props.history.push(path, {
            "patientId": this.state.patientId,
            "followUpType": followUpType,
            "patientName": this.state.patientName,
            "followUpId": this.state.followUpType
         });
      } else {
         const path = `/doctorIndex/manage/followupvisitpaper2`; //日常随访(FollowUpVisitPaper2)
         this.props.history.push(path, {
            "patientId": this.state.patientId,
            "followUpType": followUpType,
            "patientName": this.state.patientName,
            "followUpId": followUpType,
            "orderDate": this.state.orderDate == null?this.state.followups[this.state.followups.length-1]["nextTime"]:this.state.orderDate,
            "treatMonth": this.state.treatMonth
         });
      }
   }

   render() {
      //const {classes, followUpBasic, fileUrl, followUpHistoryList} = this.props;
      const { classes } = this.props;
      let followUpHistoryList = this.state.followups


      return (
         <div className={classes.root}>
            <Grid item xs={12} className={classes.Patient}>
               <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
               <Grid item xs={10} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者历史随访记录</h5></Grid>
               <Grid item justify="center" className={classes.hideMe1} xs={2}>
                  <Typography h6 className={classes.title} onClick={this.addFollowup} >添加</Typography>
               </Grid>
            </Grid>
            <Typography h5 noWrap className={classes.spacemargin}></Typography>
            <Grid container justify="center" wrap="nowrap" spacing={2}>
               <Typography className={classes.titleMain} h5 color="textSecondary" gutterBottom>肺结核患者历史随访记录</Typography>
            </Grid>
            <Grid className={classes.tableDIV}>
               <Typography h5 noWrap className={classes.spacemargin}></Typography>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        姓名：<span className={classes.title}>{this.state.patientName}</span>
                     </Typography>
                  </Grid>
                  <Grid item>
                     <Typography className={classes.titleBold} gutterBottom>
                        登记号：<span className={classes.title}>{this.state.patientNumber}</span>
                     </Typography>
                  </Grid>
               </Grid>

            </Grid>


            <Grid container justify="center" wrap="nowrap" spacing={2}>

            </Grid>

            <hr className={classes.hr}></hr>

            <Grid className={classes.clickDiv}>
               {(followUpHistoryList || []).map((item) => {
                  if (item.source == 1) {
                     return (
                        <Grid container key={item.id} justify="flex-start" wrap="nowrap" spacing={2}>
                           <Grid item>
                              <Link className={classes.click} onClick={() => {
                                 this.handleHistoryFollowUpDetails(item)
                              }} component="button" variant="body2"> <span>【{item.followUpTime}】</span>首次随访记录 -- {item.followupName}</Link>
                           </Grid>
                        </Grid>
                     )
                  }
                  return (
                     <Grid container key={item.id} justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                           <Link className={classes.click} onClick={() => {
                              this.handleHistoryFollowUpDetails(item)
                           }} component="button" variant="body2"> <span>【{item.followUpTime}】</span>日常随访记录 -- {item.followupName}</Link>
                        </Grid>
                     </Grid>
                  )
               })
               }
            </Grid>
         </div>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      serverInfo: state.serverInfo,
      followUpHistoryList: state.patientManageReducer.followUpHistoryList
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      queryFollowUpHistory: (patientId) => { //查询随访历史
         dispatch(followUpHistory(patientId))
      }
   }
}

//css region
const styles = theme => ({
   root: {
      flexGrow: 1,
      overflow: 'hidden',
      background: '#fff',
      minHeight: '100%',
   },
   Patient: {
      display: 'flex',
      alignItems: 'center',
      width: '100vw',
      height: '8vh',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   titleMain: {
      marginLeft: '3vw',
      fontSize: '5.5vw',
      lineHeight: '8vw',
      color: '#333333',
      fontWeight: '700',
   },
   jiantou: {
      height: '4vw',
      float: 'left',
      marginLeft: '4vw'
   },
   headers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '77vw',
      height: '8vh',
   },
   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
   },
   paper: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
   },
   file: {
      display: 'none',
      visibility: 'hidden',
   },
   buttonsTwo: {
      margin: theme.spacing(1),
      position: 'relative',
      width: '25vw',
      height: '8vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      border: '1px solid #00C9E4',
      borderRadius: '5px',
      padding: '4px 12px',
      overflow: 'hidden',
      color: '#fff',
      textDecoration: 'none',
      lineHeight: '8vw',
      fontSize: '4.2vw'
   },

   buttons: {
      margin: theme.spacing(2),
      fontSize: '4.8vW',
      width: '91vw',
      height: '12vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',
      margin: '18px 0',
   },
   textField: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      fontWeight: '300',
      width: '91vw',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
   },
   input: {
      width: '13vw'
   },
   inputs: {
      marginBottom: theme.spacing(1),
      width: '88vw'
   },
   inputTexts: {
      // width: '28vw'
   },

   inputName: {
      marginBottom: theme.spacing(1),
      fontWeight: '500',
      width: '67vw'
   },

   inputDiv: {
      width: '12vw',
      textAlign: 'center',
   },
   rootDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
   },
   parge: {
      width: '100vw',
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '30vh'
   },
   title1: {
      color: '#000000',
      fontSize: '4vw',
      fontWeight: '500'
   },

   titleBold: {
      margin: theme.spacing(0),
      fontSize: '4.2vw',
      lineHeight: '5vh',
      fontWeight: '700',
      color: '#333333'
   },

   doctor: {
      marginTop: theme.spacing(1),
      fontSize: '4.2vw',
      lineHeight: '5vh',
      fontWeight: '700',
      color: '#333333'
   },

   title: {
      marginBottom: 0,
      fontSize: '4.2vw',
      lineHeight: '8vw',
      fontWeight: '500',
      color: '#333333'
   },
   margin: {
      margin: theme.spacing(2),
   },

   spacemargin: {
      margin: theme.spacing(2),

   },
   click: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: '#00C9E4',
      textDecoration: 'none',
      underline: 'none'
   },

   clickDiv: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(3),
   },

   tableDIV: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },

   inputShort: {
      width: '10vw',
   },

   hideMe: {
      display: 'none'
   },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpHistory));
